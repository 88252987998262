var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { border, borderColor, borderStyle, borderWidth, height, overflow, position, space, width, } from 'styled-system';
var VisuallyHidden = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  clip: rect(0 0 0 0);\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  clip: rect(0 0 0 0);\n"])), borderStyle, borderWidth, borderColor, border, width, height, space, overflow, position);
VisuallyHidden.defaultProps = {
    borderStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.weak',
    width: '1px',
    height: '1px',
    m: '-1px',
    p: 'sp_0',
    overflow: 'hidden',
    position: 'absolute',
};
export default VisuallyHidden;
var templateObject_1;
