var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alignItems, display, position, space } from 'styled-system';
import { transitionProperty } from '../../theme/system-utilities';
import { Button } from './index';
var MovableButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n"])), display, space, position, alignItems, transitionProperty, function (_a) {
    var hidden = _a.hidden, hideToLeft = _a.hideToLeft, hideToRight = _a.hideToRight;
    return hidden
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          opacity: 0;\n          pointer-events: none;\n          ", "\n          ", "\n        "], ["\n          opacity: 0;\n          pointer-events: none;\n          ", "\n          ", "\n        "])), hideToLeft && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            left: ", ";\n          "], ["\n            left: ", ";\n          "])), hideToLeft), hideToRight && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            right: ", ";\n          "], ["\n            right: ", ";\n          "])), hideToRight)) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          opacity: 1;\n          left: 0;\n          right: 0;\n          pointer-events: initial;\n        "], ["\n          opacity: 1;\n          left: 0;\n          right: 0;\n          pointer-events: initial;\n        "])));
});
MovableButton.defaultProps = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    kind: 'bare',
};
export default MovableButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
